export default
 {
    title: {
        ar: "شاشة القفل 🔒",
        en: "Lock screen 🔒"
    },
    lead: {
        ar: "هذه الشاشة موجودة لحماية حسابك، في حالة خروجك مؤقتا من النظام او عدم استعماله لفترة من الزمن او الخروج منه، رجائا قم بإدخال كملة المرور مجددا اذا كنت تريد الدخول الى النظام، او الضغط على خيار لست انا ذا كنت تريد الدخول بحساب أخر",
        en: "This screen is there to protect your account, if you are temporarily logged out of the system or not using it for a period of time or exit from it, please re enter youre password if you want to enter the system, or click on the “Not me” option if you want to log in with another account"
    },

    password: {
        ar: "كلمة المرور",
        en: "password"
    },

    login: {
        ar: "هذا ليس أنا",
        en: "This is not me"
    },

    unlock: {
        ar: "إلغاء القفل",
        en: "Unlock"
    }
    
}