import ReactLoading from 'react-loading';
// import { Section, Article, Prop } from "./generic";

const Spinner = ({ opacity, bg, gate, way }) => {

    const style = {
        backgroundColor: bg || '#000',
        opacity: `${opacity ? 0.8 : 1}`,
        zIndex: 100
    }
    return (
        <div className='loading-parent' style={style}>
            <div className="loading-child">
                <ReactLoading type='bars' color={way || 'var(--main-blue-color)'} width="100px" height="100px" />
                <h1 style={{ color: gate || '#fff' }}>
                    Gate<span style={{ color: way || 'var(--main-blue-color)' }}>Way</span>
                </h1>
            </div>
        </div>
    )
};


export default Spinner;