export default route => {
    // 1) convet the rout to string to avoid any unwanted senarios
    let rout = route.toString() ;
    // 2) splet the url by "/" syple to get the wanted part whech the second one sence the first would be empthy /a/b ["","a","b"]
    let part = rout.split("/")[1] ;
    // 3) splip the part by "-" to get the culture ar-auth ["ar","auth"]
    let culture = part.split("-")
    // 4) chek if the culture contain the vaules of ar or en to put the defult behavior and avoid any unwanted senarios
     return ( culture.includes("ar") || culture.includes("en") ) ? culture[0] : "ar" ; 

}