export default {
  logout: {
    ar: 'تسجيل الخروج',
    en: 'Logout'
  },
  welcome: {
    ar: 'مرحبا !',
    en: 'Welcome !'
  },
  myprofile: {
    ar: 'ملفي الشخصي',
    en: 'My profile'
  },
  settings: {
    ar: 'الإعدادات',
    en: 'Settings'
  },
  activity: {
    ar: 'النشاطات',
    en: 'Activity'
  },
  support: {
    ar: 'الدعم',
    en: 'Support'
  }
}